export const ORDER_DEFAULT = {
  uid: null,
  number: null,
  bride: null,
  bridegroom: null,
  weddingDate: null,
  package: null,
  partner: null,
  status: 'pending',
  account: {
    email: null,
    password: null,
    language: 'uk',
    enablePhotosPublishing: false,
    enablePartsSelector: false
  },
  contacts: {
    email: null,
    instagram: null,
    telegram: null
  },
  location: {
    city: null
  },
  payment: {
    currency: 'UAH',
    discount: 0,
    price: 0,
    pricingVersion: 1,
    status: 'pending'
  },
  options: {
    customSubdomain: false,
    unlimitedHosting: false
  },
  website: {
    id: null,
    template: null,
    subdomain: null,
    personalization: true,
    publishing: false,
    published: false,
    publishedAt: null
  },
  createdAt: null
}

export const ORDER_DEFAULT_DATA = {

}

export const ORDER_OPTIONS = [
  {
    key: 'unlimitedHosting',
    price: 290
  },
  {
    key: 'customSubdomain',
    price: 290
  }
]

export const ORDER_PACKAGES = [
  {
    key: 'essential',
    name: 'ESSENTIAL',
    color: '#E91E63',
    currency: 'UAH',
    price: 700,
    deprecated: true
  },
  {
    key: 'gold',
    name: 'GOLD',
    color: '#9C27B0',
    currency: 'UAH',
    price: 1400,
    deprecated: true
  },
  {
    key: 'premium',
    name: 'PREMIUM',
    color: '#673AB7',
    currency: 'UAH',
    price: 2100,
    deprecated: true
  },
  {
    key: 'mini',
    name: 'MINI',
    color: '#E91E63',
    currency: 'UAH',
    price: 1400,
    deprecated: false
  },
  {
    key: 'max',
    name: 'MAX',
    color: '#673AB7',
    currency: 'UAH',
    price: 1900,
    deprecated: false
  }
]

export const ORDER_STATUSES = [
  { key: 'pending', icon: '⏳', class: 'i-hourglass', name: 'Pending', color: '#BDBDBD' },
  { key: 'ready', icon: '👍', class: 'i-thumbs-up', name: 'Ready', color: '#FF8F00' },
  { key: 'progress', icon: '💥', class: 'i-collision', name: 'In Progress', color: '#FFC107' },
  { key: 'preview', icon: '👀', class: 'i-eyes', name: 'Preview', color: '#FDD835' },
  { key: 'payment', icon: '💸', class: 'i-money', name: 'Payment', color: '#039BE5' },
  { key: 'completed', icon: '✅', class: 'i-check', name: 'Completed', color: '#4CAF50' },
  { key: 'archived', icon: '📦', class: 'i-package', name: 'Archived', color: '#BDBDBD' }
]

export const PAYMENT_STATUSES = [
  { key: 'pending', name: 'Очікуємо', color: '#BDBDBD' },
  { key: 'half', name: '50%', color: '#BDBDBD' },
  { key: 'paid', name: 'Оплачено', color: '#4CAF50' },
  { key: 'none', name: 'Непотрібно', color: '#607D8B' }
]

export const WEBSITE_LANGUAGES = [
  { key: 'uk', title: 'Українська' },
  { key: 'en', title: 'English' },
  { key: 'de', title: 'Deutsch' },
  { key: 'fr', title: 'French' },
  { key: 'it', title: 'Італійська' },
  { key: 'sq', title: 'Албанська' },
  { key: 'ka', title: 'Georgian' },
  { key: 'cs', title: 'Čeština' },
  { key: 'pl', title: 'Poland' },
  { key: 'es', title: 'Spanish' },
  { key: 'ro', title: 'Румунська' },
  { key: 'no', title: 'Норвезька' },
  { key: 'ru', title: 'ₚосійська' }
]

export const TEMPLATE_DESIGNS = [
  {
    key: 'bloom',
    name: 'Bloom'
  },
  {
    key: 'elegant',
    name: 'Elegant'
  },
  {
    key: 'minimalistic',
    name: 'Minimalistic'
  },
  {
    key: 'flora',
    name: 'Flora'
  }
]

export const TEMPLATE_VERSIONS = [
  '1.4',
  '1.3'
]
